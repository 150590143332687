.MuiButtonBase-root {
  color: rgb(92, 87, 87) !important;
}

.sidebarListItem a.link {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  color: rgb(83, 83, 83) !important;
}

.searchBar {
  margin-top: 10px;
  color: #2f323b !important;
}

.model-selector {
  appearance: none;
  padding: 10px;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  width: 200px;
}

.model-selector:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.model-selector option {
  padding: 10px;
}

.MuiInputBase-root,
.MuiTablePagination-actions {
  margin-top: -15px !important;
}

/* DARK MODE */

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.sidebarTitle {
  color: #9a9a9a !important;
}


.css-1nnrorg-MuiPaper-root-MuiAppBar-root {
  background: linear-gradient(to right, #ffffff 0%, #ffcccc 100%) !important;
}

a.link.active .sidebarListItem {
  color: #2f323b !important;
}

.sidebarListItem:hover {
  color: #2f323b !important;
}

/* ALL FORM CSS  */
.userDetCont {
  display: flex;
  min-width: 560px;
  margin: 60px 10px 0px 10px;
  padding: 20px;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubmitBtnBox {
  display: flex;
  align-items: center;
}

.detailTitle {
  font-size: 24px;
  font-weight: 600;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}



.updateDataBtn {
  background: #ED8C5B;
  margin-left: 20px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: none !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 4px 15px;
  font-family: "Arial", Helvetica, sans-serif;
  /* Removed "!important" */
  border-radius: 20px;
  transition: border 0.2s ease;
  /* Include the border in the transition for a smooth effect */
  cursor: pointer;
}

.updateDataBtn:hover {
  background: #df713a;
}

.outerMost {
  padding: 20px;
}

.secondBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
}

.mainBoxx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.headingDiv {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.imGCont {
  padding: 10px;

  margin-top: 40px;
}

.myImg {
  object-fit: cover;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  min-width: 250px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.formContainer {
  flex: 3;
  padding: 40px;
}

.formInMain {
  display: grid;
  grid-template-columns: 1.6fr 3fr;
}

.myLabel {
  padding: 5px;
  margin: 10px;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
}

.myInput {
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dbb3b3;
}

.smallboxes {
  margin: 10px;
}

.PanelTopBar {
  background: linear-gradient(180deg, #ED8C5B 0.01%, #CE2C49 99.99%) !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;
}

body {
  background-color: #ecf2ff !important;
}

.loader {
  margin-top: 300px;
}

a {
  text-decoration: none !important;
  font-size: 18px !important;
  color: black !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  font-size: 14px !important;
  font-weight: 300 !important;
  background-color: #10121b !important;
  color: white !important;
}

.newBtn {
  background: #ED8C5B;
  margin-left: 20px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 4px 15px;
  font-family: "Arial", Helvetica, sans-serif;
  /* Removed "!important" */
  border-radius: 20px;
  transition: border 0.2s ease;
  /* Include the border in the transition for a smooth effect */
  cursor: pointer;
}

.newBtn:hover {
  background: #df713a;
}


.errorMessage {
  font-size: 13px;
  font-weight: 400;
  color: #555555;
  /* or any other shade of grey you prefer */
  padding: 10px;
  margin: 10px 0;
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.fileErr {
  font-size: 14px;
  color: red;
  text-align: center;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard {
  margin-top: -31px !important;
}

.topBarIcon {
  color: white !important;
  font-size: 30px !important;
}

.popUpCreate {
  background-color: white;
  padding: 30px;
  width: 590px;
}

.popHeading {
  font-size: 22px;
  margin-top: 10px;
  color: black;
  font-family: ui-monospace;
  font-weight: 500;
}

.popText {
  color: black;
  font-size: 15px;
  font-family: ui-monospace;
  margin-left: 7px;
  font-weight: 500;
  margin-top: 10px;
}


.uploddIcon {
  font-size: 40px !important;
  color: lightgray;
}

.popInput {
  width: 540px;
  background: transparent;
  padding: 11px;
  border-radius: 50px !important;
  outline: none;
  border: 1px solid lightgray;
  color: black;
}

.popInput1 {
  width: 400px;
  background: transparent;
  padding: 11px;
  border-radius: 50px !important;
  outline: none;
  border: 1px solid lightgray;
  color: black;
}

.popInput1:disabled {
  background-color: rgba(0, 0, 0, 0.2);
}

.popInput2 {
  width: 900px;
  background: transparent;
  padding: 11px;
  border-radius: 50px !important;
  outline: none;
  border: 1px solid lightgray;
  color: black;
}

.popInput3 {
  width: 900px;
  height: 180px;
  background: transparent;
  padding: 11px;
  border-radius: 50px !important;
  outline: none;
  border: 1px solid lightgray;
  color: black;
}

.popInputeventDetail {
  width: 485px;
  background: transparent;
  padding: 11px;
  border-radius: 50px;
  outline: none;
  border: 1px solid lightgray;
  color: black;
}

.popInput:focus {
  outline: none;
  border: 1px solid lightgray;
}

.popInputPhoto {
  width: 250px;
  background: rgba(0, 0, 0, 0);
  padding: 11px;
  border-radius: 50px;
  outline: none;
  border: none;
  color: white;
}

.popBtn {
  background: white;
  color: black;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  padding: 8px 25px;
  font-family: ui-monospace !important;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.statusChipPending {
  background-color: #f0ad4e !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.statusChipCompleted {
  background-color: #5cb85c !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.statusChipFailed {
  background-color: #d9534f !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.errText {
  font-size: 15px;
  color: red;
  margin-left: 8px;
}

.purchaseChip {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  gap: 4px;
  padding: 5px 0px 4px 8px;
  background: linear-gradient(to bottom right, #669999 0%, #333399 100%);
  font-size: 10px;
  font-weight: 500;
  color: white;
  border-radius: 30px;
}

.removedUser-Access {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #c52828;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
}

.adminCreation {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #1886e0;
  padding: 8px 12px;
  border-radius: 20px;
  gap: 5px;
}

.hiddenTopic {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #c52828;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
}

.newMessage {
  position: relative;
  display: inline-flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #00a331;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
  overflow: hidden;
}

.newMessage::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 20px;
  background-color: rgba(0, 163, 49, 0.5);
  opacity: 0;
  animation: glowing 2s ease-in-out infinite;
}

@keyframes glowing {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}



.featuredPostStatus {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to left, #006600 0%, #339933 100%);
  padding: 6px 10px;
  border-radius: 20px;
  gap: 5px;
}

.normalPostStatus {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to left, #0099cc 0%, #0066cc 100%);
  padding: 7px 10px;
  border-radius: 20px;
  gap: 5px;
}

.detailPageImage {
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
}

.tabs {
  width: 100%;
}


.MuiTabs-flexContainer {
  display: flex !important;
  gap: 350px !important;
}