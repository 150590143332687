.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #ebe9e9;
}
.container{
margin: 0;
padding: 0;
}
.logoImg{
    height: 30px;
    margin-top: 5px;
    width: 30px;
    cursor: pointer;
}

.link {
     text-decoration: none;
}
.spanName{
    margin-left: 16px;
    font-size: 20px;
    font-weight: 400;
    color: aliceblue;
}

.menuIcons{
    padding: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
li .MuiButtonBase-root:hover{
    background-color: #212122;
}

.topIconbadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: #b0bfec;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}


.topAvatar{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}
.topAvatar:hover{
    box-shadow: rgba(243, 96, 91, 0.945) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ;
    /* margin-top: 1%; */
    transform: translate(0, -1px);
}

.topRight{
    display: flex;
    align-items: center;
    
}
.topbarWrapper{
    height: 100%;
    width: 100%;
    padding: 0px;
    background: linear-gradient(180deg, #ED8C5B 0.01%, #CE2C49 99.99%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}