
.sidebarWrapper{
    padding: 10px 10px 218px 10px;
    margin-top: 40px;
    background-color: white !important;
}
.sidebarMenu{
    margin-bottom: 10px;
}
.sidebarTitle{
    font-size: 15px;
    color:    #302e2e;
}
.sidebarList{
    list-style: none;
    padding: 5px;
}
.sidebarListItem {
    padding: 7px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    align-items: center;
    border-left: 3px solid transparent;
    color: black;
    transition: all 0.3s ease; 
  }
  
  .sidebarListItem:hover {
    background: white;
    border-left: 3px solid #ED8C5B;
    color: black !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }
  


a.link.active .sidebarListItem{
    background: white;
    padding-left: 12px;
    border-left: 3px solid;
    border-color: #ED8C5B;
    color: black !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    
}

.sidebarIcon{
    margin-right: 15px;
    font-size: 20px !important;
}