.userList {
    flex: 4;
    padding: 20px;
  }
  
  .mainBox {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
      rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  }
  
  /* .topNavigator{
        background-color: rgb(219, 184, 184);
        margin-bottom: 40px;
        border-radius: 6px;
        color: #443d3d;
    }
    .pTag{
        padding: 6px;
        font-weight: 600;
        font-size: 14px;
    } */
  
  .userListUser {
    display: flex;
    align-items: center;
  }
  
  .email.true {
    color: #73b998 !important;
    /* color: white !important; */
  }
  
  .block {
    text-decoration: none;
    border: none;
    cursor: pointer;
    outline: none;
    transition: 0.2s all;
    margin-right: 20px;
    margin-left: 0;
  }
  
  .block:active {
    transform: translateY(2px);
  }
  
  .visibilityIc.active {
    transform: translateY(1px);
  }
  
  .block.true {
    color: #0f6e40 !important;
  }
  
  .searchBar {
    display: flex;
    background-color: #f3f3f3;
    align-items: center;
    margin-bottom: 9px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .searchBarInput {
    border: none;
    font-size: 15px;
    outline: none;
    padding: 11px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 300px !important;
  }
  
  .searchIcon {
    margin-left: 5px;
    font-size: 30px !important;
    color: #a9a9a9 !important;
    cursor: pointer;
  }
  
  /* .email.false{
        background-color: #c94f4f !important;
        color: white !important;
    } */
  .block.false {
    color: red !important;
  }
  
  .userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .userListEdit {
    border: none;
    border-radius: 10px;
    padding: 2px 6px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .visibilityIc {
    margin-left: 0;
    font-size: 25px !important;
    cursor: pointer;
    color: rgb(26, 121, 128);
  }
  
  .blockIcon {
    cursor: pointer;
    font-size: 25px !important;
    font-weight: 600;
  }
  
  .userListDelete {
    color: #c52424;
    font-size: 25px !important;
    cursor: pointer;
  }
  
  .userListDelete:active {
    transform: translateY(2px);
  }
  
  .chipSeller.Pending {
    color: rgb(44, 44, 41) !important;
  }
  
  .block:hover {
    cursor: pointer;
  }
  
  .chipSeller {
    background-color: rgb(235, 220, 201) !important;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ; */
  }
  
  .chipEmail {
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ; */
    background-color: #ebebd4 !important;
  }
  
  .gridBox {
    background-color: white !important;
  }
  
  .Not.uploaded {
    color: rgb(145, 141, 141) !important;
  }
  
  .Verified {
    color: #0f6e40 !important;
  }
  
  .Approved {
    color: #0f6e40 !important;
  }
  
  .Rejected,
  .Not.Verified {
    color: #bb3030 !important;
  }
  
  .addUnitInput {
    width: 350px;
    padding: 10px 18px;
    margin: 8px 0;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  
  .addUnitInput:focus {
    border: 3px solid #7e7c7c;
  }
  
  .statusChip {
    background-color: #729728 !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }
  
  .statusChipPr {
    background-color: #226cb1 !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }
  
  .FreeBreakfastSharp {
    color: white !important;
    font-size: 23px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .WorkspacePremium {
    color: white !important;
    font-size: 23px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .statusChipVerified {
    background-color: #2c9728 !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }
  
  .statusChipActive {
    background-color: #2c9728 !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }
  
  .statusChipNonActive {
    background-color: #ff9532d8 !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }
  
  
  .statusChipBlock {
    background-color: #a72a0a !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }
  
  
  .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable {
    background-color: #10121b !important;
    color: white !important;
  }
  
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
    color: white !important;
  }
  
  