.topNavigator{
    background-color: white;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    margin-top: 70px;
}
.pTag{
    font-size: 10px;
    font-weight: 300;
    margin-left: 14px;
    padding: 3px;
    
}