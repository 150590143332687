
.cardWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardContainer {
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    flex-direction: column;
    margin: 10px;
    background-color: white;
    min-width: 300px;
    padding: 20px;
  
}



.cardTitle {
    font-size: 22px;
    font-weight: 400;
    color: #495057;
    text-align: right;
}

.cardContainer:hover .cardTitle {
    color: #495057;
}
.cardContainer:hover .cardLogo {
    color: #495057;
}


.cardTopLogo {
    margin-bottom: 56px;
    text-align: right;
}

.cardLogo {
    align-items: flex-end;
    color: #495057;
    font-size: 50px !important;
}

.userCount{
    margin-top: 4px;
    color: #495057;
    font-weight: 400;
    font-size: 20px;
    align-items: center;
}
.trendingLogo{
    margin-right: 6px;
    font-weight: 300 !important;
    font-size: 30px !important;
}
