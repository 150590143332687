/* BeautifulSlider.css */

.slider-container {
    width: 300px;
    margin: 100px auto;
    text-align: center;
    padding: 20px;
  }
  
  .custom-slider {
    width: 100%;
    height: 10px;
    appearance: none;
    background: linear-gradient(to right, #007bff 0%, #007bff 100%);
    border-radius: 5px;
    outline: none;
    margin: 10px 0;
    z-index: 1;
    /* background: linear-gradient(to right, #ff5722 0%, #ff5722 100%); */
  }
  
  .custom-slider::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 2; 
  }

  .custom-slider-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #ff5722 0%, #ff5722 100%);
    z-index: 0;
  }
  
  .slider-value {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .slider-wrapper {
    position: relative;
  }
  
  .slider-arrow {
    position: absolute;
    top: 100%;
    left: calc((100% - 20px) * 0.5); /* Center the arrow under the slider */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #007bff;
  }


  .submit-button {
    background: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    animation-duration: 1s;
    animation-fill-mode: both;
    border-radius: 5px;
  }
  
  .button-piece {
    position: absolute;
    background: #007bff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0.7;
    transform-origin: center;
    pointer-events: none;
  }